import type { StdObject } from '~/types/internal'
import type { ProductData, ProductDataList } from '~/types/product'

export const productQuery = (
  id: string,
  query?: object,
  thumbs?: StdObject,
  hasPreview?: { expires: string; signature: string },
  placeholderData?: ProductData,
  forceData: boolean = false
) =>
  queryOptions({
    queryKey: ['product-detail', id],
    queryFn: async (): Promise<ProductData> => {
      // Nefetchuje z BE pokud již data má ale queryClient není k dispozici (např. v middleweru)
      if (forceData && placeholderData) {
        return placeholderData
      }
      const res = await useCustomFetch<ProductData>(
        `/public/products/${hasPreview ? 'p/' : ''}${id}`,
        { ...query, ...(hasPreview ?? {}) },
        thumbs,
        {
          key: 'product-detail',
        }
      )
      return res?.data?.value as ProductData
    },
    placeholderData,
  })

export const productsQuery = ({
  enabled = shallowRef(true),
  query = shallowRef({}),
  pageNumber = shallowRef(1),
  thumbs,
  placeholderData,
}: {
  enabled?: Ref<boolean>
  query?: Ref<object>
  pageNumber?: Ref<number>
  thumbs?: StdObject
  placeholderData?: ProductDataList
}) =>
  queryOptions({
    enabled,
    queryKey: ['products', query],
    queryFn: async (): Promise<ProductDataList> => {
      const res = await useCustomFetch<ProductDataList>(
        '/public/products',
        {
          ...query?.value,
          page: {
            ...(query?.value && 'page' in query.value && typeof query.value?.page === 'object'
              ? query?.value.page
              : {}),
            number:
              pageNumber?.value ??
              (query?.value &&
              'page' in query?.value &&
              typeof query?.value?.page === 'object' &&
              query.value?.page &&
              'number' in query.value?.page
                ? query.value?.page.number
                : undefined),
          },
        },
        thumbs
      )
      console.log(res?.data?.value)
      return res?.data?.value as ProductDataList
    },
    placeholderData,
  })

export const infiniteProductsQuery = (query?: Ref<object>, thumbs?: StdObject) =>
  infiniteQueryOptions({
    queryKey: ['products', query],
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const res = await useCustomFetch<ProductDataList>(
        '/public/products',
        {
          ...query?.value,
          page: {
            ...(query?.value && 'page' in query.value && typeof query.value.page === 'object'
              ? query.value.page
              : {}),
            number: pageParam,
          },
        },
        thumbs
      )
      return res?.data?.value as ProductDataList
    },
    getNextPageParam: (currentPage) => {
      if (!currentPage.meta.hasMorePages) {
        return undefined
      }
      return currentPage.meta.currentPage + 1
    },
    getPreviousPageParam: (currentPage) => {
      if (currentPage.meta.currentPage === 1) {
        return undefined
      }
      return currentPage.meta.currentPage - 1
    },
  })
